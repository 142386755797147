// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "@firebase/firestore"; 

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAXHdvc2Rf42tEr0Z88WDHmDa85L2NCXVU",
    authDomain: "investingthing.firebaseapp.com",
    projectId: "investingthing",
    storageBucket: "investingthing.appspot.com",
    messagingSenderId: "927850022052",
    appId: "1:927850022052:web:ca8e2de631cc8d66781231",
    measurementId: "G-HC33YQERG5"  };
  
  
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app); 