import { useState, useEffect} from "react"; 
import './App.css';
import { db } from './firebase-config';
import { collection, getDocs } from "firebase/firestore";
function App() {
  

  return (
    <div>Coming Soon</div>
  );
}

export default App;
